@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

// Assessment & report containers adjusted top padding to accomodate header absolute positioning
.awscat-assessment-details,
.awscat-assessment-results,
.awscat-assessment-manage-sponsor {
    padding-top: 9.5rem !important;
}

.awscat-assessment-wrapper {
    min-height: 82vh;
}

// Wrapper for spinner that keeps aws disclaimer footer from appearing near the top of the browser
.spinner-wrapper {
    padding: 1.7rem;
    min-height: 100vh;
}

// Constrain single page forms to Polaris standards for form width
.assessment-form {
    min-width: 17.5rem;
    max-width: 70rem;
}

// Assessment details wrapper
.awscat-assessment-details {
    background: #f2f3f3 !important;
    position: relative;
}

// Assessment header
.assessment-header {
    width: 100%;
    padding: 2.2rem 0 1.5rem;
    border-bottom: #bbb solid 1px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;

    h1 {
        padding: 0 !important;
        margin: 0 !important;
        font-size: 14px !important;
        font-weight: bold !important;
        line-height: 1.2em !important;
        color: #16191f;

        span.awscat-assessment-description {
            display: inline-block;
            padding-left: 1rem;
            font-weight: 500;
            color: var(--color-text-status-inactive-MF2TP-, #687078);
        }
    }

    .awscat-assessment-progress {
        margin-top: -0.7rem;

        small div {
            position: relative;
            top: -0.7rem;
        }
    }

    .awsui_badge_1yjyg_1gkjh_3 {
        margin-top: 0.63rem;
    }

    .awscat-assessment-header-bar-components {
        margin-top: 0.9rem;

        .awscat-assessment-update-session-state {
            margin-left: 0.4rem;
        }
    }
}

// Assessment questions
.awscat-assessment-details-section-title {
    width: fit-content;
    max-width: 90rem;
    margin: 3rem auto 3rem  !important;
    text-align: center;
    
    h1 {
        color: rgba(0, 0, 0, 0.6) !important;
    }
}

.awscat-assessment-details-heading {
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.3;
}

.awscat-assessment-details-prompt {
    width: 95%;
    max-width: 150rem;
    min-height: 50rem;
    margin: 0 auto 2rem !important;
    padding: 1rem 0.6rem 3rem 0.6rem;
    text-align: center;
    font-size: 1.3em !important;
    font-weight: 400 !important;
    line-height: 1.3 !important;
    color: #16191f !important;
    background: #fff;
    border: solid 1px #ccc;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;

    h2 {
        font-weight: 500 !important;
        font-size: 1.15em !important;
    }
}

.awscat-assessment-details-prompt-single-column {
    height: 70%;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.awscat-assessment-details-prompt-left-column {
    width: 30%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.awscat-assessment-details-prompt-right-column {
    width: 57%;
    text-align: center;
}

.awscat-assessment-results-grid {
    margin: 1.5rem 0.8rem 0.8rem;
    border: lightgrey medium solid;
}

// Assessment footer
footer.assessment-footer {
    width: 100%;
    padding: 1.5rem 0;
    border-top: solid 1px #d5dbdb;
    background: #fff;
    bottom: 0;
}

.assessment-details-modal > div > div {
    max-width: 1500px !important;
}
