#page-footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // corresponds to the z-index in Mezzanine
    z-index: 1000;
    margin: 0;
}
