@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use '../../../../../Common.scss' as appui;

// The name of the perspective or workstream
.facet-name {
    font-size: larger;
    font-weight: bold;
}

// Score for entire perspective or workstream
.facet-score {
    font-size: larger;
    text-align: right;
}

// Container for facet-name and facet-score. Extensions ('-incomplete', '-good', etc) determine color
.facet-header {
    text-align: left;
    font-size: large;
    font-weight: bold;
    vertical-align: middle;
    border-bottom: 2px solid appui.$grey_border;
    padding: 15px;
}

// Gives a transparent border - used to match size of bordered elements
.transparent-bordered {
    border: 2px solid transparent;
}

// Entire box for a workstream or perspective score
.facet-scores {
    background-color: white;
    border-top: var(--border-divider-list-width-3SLtCq, 1px) solid var(--color-border-container-top-bpGAxB, #eaeded);
    box-shadow: 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426;
    box-shadow: var(--shadow-container-kBIjjg, 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426);
}

// a scored item (activity or category)
.scored-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// Wrapper for something scored (activity or category)
.scored-item-box {
    padding: 10px 0 10px 0;
    margin: 0;
    text-align: right;
    vertical-align: middle;
}

// Wrapper for set of scored items
.scored-items-box {
    border-right: 2px solid grey;
    padding: 15px 5px 15px 15px;
    margin: 0;
    vertical-align: middle;
}

// Wrapper for set of scores
.scores-box {
    padding: 15px 15px 15px 0;
    text-align: right;
    vertical-align: middle;
}

// Wrapper for a score
.score-box {
    padding: 10px 5px 10px 0;
    text-align: right;
    vertical-align: middle;
}

// A barscore. Extensions ('-incomplete', '-good', etc) provide colors, borders, etc.
.barscore {
    padding-right: 10px;
    border: 2px solid appui.$grey_border;
    border-left: none;
}
