@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.awscat-assessment-details-comments {
    height: 100%;
    width: 100%;
    min-width: 280px;
    margin: 0 0 2rem;
}

.awscat-assessment-comments-thread {
    width: 10rem;
    margin: auto;
}
