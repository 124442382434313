.awscat-assessment-number-input {
    width: 100%;
    height: 100%;
    margin: 2.5rem 0;
    display: flex;
    flex-direction: column;
}

.awscat-assessment-number-input input[type='number'] {
    height: 8rem;
    width: 100%;
    border: solid 1px #eee;
    border-bottom: solid 2px #0073bb;
    font-size: 7rem;
    text-align: center;
    font-weight: 700;
    background: #fff;
    transition: all 0.6s ease-out;

    &:hover {
        border: solid 1px #cdcdcd;
        border-bottom: solid 2px #0073bb;
        transition: all 0.3s ease-out;
    }

    &:focus {
        background: #f3f3f3;
        border: solid 1px #ccc;
        border-bottom: solid 3px #0073bb;
        transition: all 0.3s ease-out;
        outline: none;
    }
}
