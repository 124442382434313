//Reuseable variables

//Tile wrapper
$tile-wrapper-height: auto;
$tile-wrapper-min-height: 6rem;
$tile-wrapper-max-height: 24rem;

//Tile Rating Guide Text


.tile-wrapper {
    position: relative;
    width: 100%;
    margin-bottom:1.5rem;

    div[class^='tile-'] {
        display: flex;
        flex: 2;
        position: relative;
        cursor: pointer;
        height: $tile-wrapper-height;
        min-height: $tile-wrapper-min-height;
        max-height: $tile-wrapper-max-height;

        .rating {
            padding: 0 3.25rem;
            font-size: 4em;
            font-weight: 900;
            text-align: center;

            .rating-align-middle {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }

        .guide-text {
            padding:1rem 0;
            position: relative;
            flex-grow: 1;
            display: flex;
            align-items: center;
            text-align: left;
            cursor: pointer;

            p.guide-content {
                display: flex;
                align-items: safe center;
                height: 80%;
                width: 100%;
                padding: 0 2rem;
                font-size: 1.1em;
                line-height: 1.2;
                position: relative;
                cursor: pointer;
                user-select: none;

                span {
                    flex: 1;
                    padding:1rem 0;
                }
            }

            .poll-vote-percentage-bar {
                height: 100%;
                background: #c4f3ff;
                top: 0;
                left: 0;
            }
        }

        //Live polling bar chart visualization
        figure {
            display: block;
            height: 100%;
            min-height: 7rem;
            width: 0%;
            margin: 0;
            background: #c4f3ff;
            position: absolute;
            transition: width 0.5s ease-out, background 0.6s ease-in-out;
            transition-delay: 0.15s;

            figcaption {
                width: 8rem;
                height: 100%;
                float: right;
                position: relative;

                span {
                    display: block;
                    position: absolute;
                    right: -5.1rem;
                    bottom: 0.3rem;
                    color: #687078;
                    font-weight: 600;
                    text-align: left;
                    user-select: none;

                    &.above-90 {
                        right: 0.8rem;
                        text-align: left;
                    }
                }
            }
        }
    }

    .has-comments {
        display: inline-block;
        position: absolute;
        top: -8%;
        right: -2.7rem;
    }

    &.is-NA {
        div[class^='tile-'] .rating {
            padding: 0 1.5rem;
            font-size: 3.5em;
            font-weight: 700;
            line-height: 8rem;
            text-transform: uppercase;
        }
    }

    &.is-IDK {
        div[class^='tile-'] .rating {
            padding: 0 3.5rem;
        }
    }

    &.yes-no-rating {
        width: 100%;

        div[class^='tile-'] {
            line-height: 8rem;

            .rating {
                flex: 1;
                align-items: center;
                border-right: none;

                .rating-align-middle {
                    display: block;
                    padding-bottom: 1.1rem;
                    position: relative;
                }
            }

            &.tile-selected {
                border: solid 1px #0073bb;

                .rating {
                    background: none;
                    color: #0073bb;
                    transition: all 0.25s ease-in;
                }

                &:hover {
                    border: solid 1px #51a9e0;
                    transition: all 0.35s ease-in;
                }
            }

            &.tile-unselected {
                &:hover .rating {
                    background: #f8fafb;
                }
            }

            figure {
                position: absolute;
                top: 0;
                left: 0;

                figcaption {
                    padding-top: 3rem;
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.tile-unselected {
    background: #ffffff;
    transition: all 0.4s ease-in;
    border: solid 1px #aaa;

    .rating {
        color: #333;
        transition: all 0.55s ease-out;
        border-right: solid 1px #aaa;
    }

    .guide-text {
        span.guide-content {
            color: #444;
        }
    }

    &:hover {
        border: solid 1px #0073bb;
        background: #f8fafb;
        box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.03);
        transform: scale(1.02);
        transition: all 0.25s ease-in;

        .rating {
            background: #f1faff;
            user-select: none;
            transition: all 0.35s ease-out;
            border-right: solid 1px #0073bb;
        }

        span.guide-content {
            color: #000;
            transition: all 0.12s ease-out;
        }

        figure {
            background: #85e7ff;
            transition: background 0.8s ease-out;
        }
    }
}

.tile-selected {
    border: solid 1px #0073bb;
    background: #f1faff;
    transition: all 0.1s ease-in;

    .rating {
        border-right: solid 1px #0073bb;
        background: #0073bb;
        color: #fff;
        user-select: none;
        transition: all 0.06s ease-out;
    }

    .guide-text {
        span.guide-content {
            color: #000;
        }

        figure {
            background: #4ddbff;
            border-right: solid 1px #98d7fd;
            transition: background 0.3s ease-out;
        }
    }

    &:hover {
        border: solid 1px #67a0c4;
        transform: scale(1.01);
        transition: all 0.24s ease-in;
    }
}

nav.select-tile-error {
    .tile-selected {
        background: #fdf3f1;
        border: solid 1px #df3312;
        transition: all 0.34s ease-in;

        .rating {
            background: #df3312;
            border-right: solid 1px #df3312;
            transition: all 0.34s ease-out;
        }

        .guide figure {
            background: #ffab9b;
        }
    }
}

//Live poll comments

.comment-thread {
    h3 {
        padding: 0;
        margin: 0;
    }

    .comment-thread-scroll {
        max-height: 35rem;
        padding-right: 2rem;
        overflow-y: auto;
    }

    .comment {
        .comment-body {
            padding: 1rem 1.2rem;
            border-radius: 0.3rem;
            border: solid 1px #aaa;
            background: #f1faff;

            p {
                margin-top: 0;
                font-size: 1.12em;
                line-height: 1.6em;
                font-weight: 500;
                color: #333 !important;
            }

            .comment-meta {
                color: #cbcbcb;

                .comment-author {
                    font-weight: 600;
                    color: #333;
                }

                .comment-date {
                    color: #666;
                }
            }
        }

        footer {
            margin-top: 0.5rem;
            color: #666;
        }
    }
}

.live-poll-comments {
    span {
        display: inline-block;
        position: relative;
        margin-left: 0.5rem;
        left: -0.4rem;
        top: -1.6rem;
        font-size: 1.35em;
        font-weight: 600;
        color: var(--color-text-counter-Qnq0Ij, #687078);
    }
}
