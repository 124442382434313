$green: #d9f5d1;
$amber: #ffedd4;
$light_red: #fdd5ce;
$grey_border: rgb(191, 197, 203);

.awscat-applayout-content {
    padding: 20px;
}

.awscat-applayout-wizard {
    padding: 20px;
    max-width: 100rem;
}

.icon-button {
    opacity: 1;
    cursor: pointer;
    transition: all 0.43s ease-out;

    &:hover {
        transform: scale(1.1);
        transition: all 0.15s ease-in;
    }
}
