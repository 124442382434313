@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.awscat-assessment-card-title-byline {
    font-size: small;
}

.icon-button.secondary {
    padding: 0.35rem 0.45rem 0.45rem;
    background: #fff;
    background: var(--color-background-button-normal-default-i32hou, #fff);
    border: 1px solid #545b64;
    border-radius: 0.3rem;
    color: #545b64;
    color: var(--color-text-button-normal-default-kwrutp, #545b64);
    position: relative;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;

    &:hover {
        background: #fafafa;
        background: var(--color-background-button-normal-hover-6vxqq6, #fafafa);
        border-color: #16191f;
        border-color: var(--color-border-button-normal-hover-993ry7, #16191f);
        color: #16191f;
        color: var(--color-text-button-normal-hover-de4793, #16191f);
        text-decoration: none;
    }

    svg {
        position: relative;
        top: 0.2rem;
    }
}
