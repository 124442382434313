@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

#b {
    // accommodate the space for the fixed header
    padding-top: 38px;
    padding-bottom: 40px;
}

#h {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // corresponds to the z-index in Mezzanine
    z-index: 1000;
    margin: 0;
    padding: 0 0 0 30px;
    background: awsui.$color-background-home-header;
    font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
}

// temporary fix for this issue: https://issues.amazon.com/issues/AWSUI-10091
// it is needed for 2.1 components and should be removed after we migrate Modal and TablePreferences
.awsui-modal-open .awsui {
    z-index: 2000;
}

// needs to be more specific than `.awsui ul` style
.awsui ul.menu-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
        padding: 0 4px;
        margin: 13px 0 9px;

        &.dropdown {
            margin: 0;

            /*
        Aligns the polaris button dropdown text vertically with the other text in the header bar
        */
            div[class^='awsui_button-dropdown_']
                > div[class^='awsui_root_']
                > div
                > div[class^='awsui_dropdown-trigger_sne0l_']
                > button[class^='awsui_trigger-button_sne0l_']:not(#\9) {
                background-color: transparent;
                border: none;
                color: awsui.$color-foreground-control-default;
                font-weight: normal;
                line-height: 3rem;
                padding: 6px 0px;
                span {
                    transform-origin: 50% 35%;
                }
            }
        }

        a,
        & > div,
        nav > div,
        & > button {
            float: left;
            color: awsui.$color-foreground-control-default;
            line-height: 16px;
        }

        /*
      Selects the carat right of the ButtonDropdown(s) in the header.
      Causes header to rotate about itself, rather than a point just above itself (this looked strange)
      */
        span[class^='awsui_icon_vjswe_']:not(#\9) {
            margin-top: 0.8rem;
            margin-left: 0;
            padding: 0;
        }

        a,
        a:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &.title {
            font-weight: bold;
        }

        &.selected > a,
        &.selected > button {
            font-weight: bold;
            color: awsui.$color-text-accent;
        }

        &.separated {
            border-left: 1px solid awsui.$color-border-control-default;
            padding-left: 9px;
            margin-left: 9px;
        }

        &.notification {
            margin: 0;
            line-height: 4rem;
            div {
                float: none;
            }
        }

        &.recent-portfolios {
            background-color: #39516f;
            padding-left: 15px;
            a,
            div,
            button {
                float: none;
            }
        }

        &.ml-auto {
            margin-left: auto;
        }
    }

    li:last-child {
        padding-right: 30px;
    }

    button,
    button:focus {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0 6px;
    }
}
