@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

// Review all/Heatmap
.awscat-assessment-heatmap-accelerator-grid {
    display: grid;
}

.awscat-assessment-heatmap-accelerator-grid-element {
    background: #f7f7f7 !important;
}

// Variables for common heatmap components
$assessment-heatmap-cell-font-color-default: #545b64;
$assessment-heatmap-cell-default: #ffffff;
$assessment-heatmap-cell-border-color: #eaeded;
$assessment-heatmap-cell-hover-transition-start: 0.15s;
$assessment-heatmap-cell-hover-transition-end: 0.55s;
$assessment-heatmap-cell-phase-font-color: #687078;
$assessment-heatmap-cell-phase-font-size: 1em;
$assessment-heatmap-grid-border: #aab7b8;

.heatmap-cell {
    min-height: 7rem;
    border-right: solid 1px $assessment-heatmap-cell-border-color;
    border-bottom: solid 1px $assessment-heatmap-cell-border-color;
    padding: 1.3rem 1.3rem 3.3rem;
    color: $assessment-heatmap-cell-font-color-default;
    position: relative;
    transition: all $assessment-heatmap-cell-hover-transition-end ease-out;

    //cell title
    .heatmap-cell-title {
        font-size: 1em !important;
        line-height: 1.3;
        user-select: none;
        text-align: center;
    }

    //footer with activity and icons
    footer {
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;

        small {
            display: inline-block;
            padding: 0 0 0.6rem 1.2rem;
            font-size: $assessment-heatmap-cell-phase-font-size;
            color: $assessment-heatmap-cell-phase-font-color;
            user-select: none;
        }

        nav {
            position: absolute;
            right: 0.65rem;
            bottom: -0.1rem;
        }
    }

    //Default state cell hover
    &:hover {
        background: darken($assessment-heatmap-cell-default, 3%);
        transition: all $assessment-heatmap-cell-hover-transition-start ease-in;

        footer small {
            color: darken($assessment-heatmap-cell-phase-font-color, 16%);
        }
    }
}

.awscat-assessment-heatmap-grid-element {
    background: #ffffff;

    h4 {
        margin: 0;
        padding: 1.35rem 0 !important;
        border-top: solid 1px $assessment-heatmap-grid-border;
        border-bottom: solid 1px $assessment-heatmap-grid-border;
        font-size: 1.12em !important;
        user-select: none;
        text-align: center;
    }

    .workstream-state-circular-indicator {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        margin-right: 1rem;
        position: relative;
        top: 0.37rem;
        content: '';
    }
}

.flex-container {
    display: flex;
    flex-direction: row;
}

// Review all/Report
.awscat-assessment-details {
    background-color: awsui.$color-background-layout-main;
}

.awscat-assessment-results {
    min-height: 700px;
    padding: 1.8rem;
    background-color: awsui.$color-background-layout-main;
}

.awscat-activity-phase {
    color: grey;
    font-size: xx-small;
}

.awscat-assessment-report {
    margin-top: 20px;
    border-style: solid;
    border-width: thin;
    border-color: lightgrey;
    font-size: xx-small;
    width: 90px;
    height: 80px;
}

.awscat-assessment-report-help-panel {
    font-style: italic;
    margin-bottom: 10px;
}

.awscat-assessment-complete-status-box {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: awsui.$color-charts-green-600;
    color: white;
}

.awscat-assessment-complete-status-text {
    color: white;
}
