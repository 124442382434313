@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use '../../../../../Common.scss' as appui;

.filter-box {
    padding: 20px;
    background-color: white;
    border-top: var(--border-divider-list-width-3SLtCq, 1px) solid var(--color-border-container-top-bpGAxB, #eaeded);
    box-shadow: 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426;
    box-shadow: var(--shadow-container-kBIjjg, 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426);
    margin-right: 50px;
}

.filter-box-filters-applied {
}

.filter-box-num-questions {
    color: #545b64 !important;
}

.filter-box-matches {
    color: #545b64 !important;
}

.heatmap-cell-v2 {
    min-height: 4.5rem;
}

.assessment-heatmap-v2 > div {
    padding-right: 30px !important;
}

.assessment-facet-section {
    margin-right: 50px;
}

.assessment-facet-name {
    vertical-align: center;
    padding: 8px 0 8px 0;
}

.question-box {
    padding: 20px;
    padding-bottom: 0;
}

.question-vector-name {
    padding: 15px;
    border-top: var(--border-divider-list-width-3SLtCq, 1px) solid var(--color-border-container-top-bpGAxB, #eaeded);
    border-bottom: 2px solid appui.$grey_border;
}

.question-vector-title {
    font-size: large;
    font-weight: 400;
}

.question-vector-questions {
    box-shadow: 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426;
    box-shadow: var(--shadow-container-kBIjjg, 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426);
    border-top: 2px solid rgba(0, 0, 0, 0);
}

.question-vector-phase {
}

.question {
}

.question-label {
}

// Below selectors are override polaris badges
.awsui .badge {
    color: black !important;
    border: 1px solid black;
}

.question-facilitator-comments-section {
}

.facilitator-comments-wrapper-marked {
    border: 2px solid #f3e502;
    padding: 0;
}

.question-facilitator-comments-label {
    color: awsui.$color-text-body-secondary !important;
}

.question-facilitator-comments {
    height: 100px;
}

.question-participant-comments {
    color: awsui.$color-text-body-secondary !important;
    padding-bottom: 10px;
}

.question-participant-comment {
}

.summary-observations-wrapper-marked {
    border: 2px solid #f3e502;
    padding: 0;
}

.summary-observations-section {
    box-shadow: 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426;
    box-shadow: var(--shadow-container-kBIjjg, 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426);
    padding: 25px 20px 25px 20px;
}

.question-divider {
    border-bottom: 2px solid lightgrey;
}

.selected-answers button {
    display: none;
}

.selected-answers div {
    padding-right: 8px !important;
}
