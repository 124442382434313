@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.recommended-actions-wrapper-marked {
    border: 2px solid #f3e502;
    padding: 0;
}

.recommended-actions-section {
    box-shadow: 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426;
    box-shadow: var(--shadow-container-kBIjjg, 0 1px 1px 0 #001c244d, 1px 1px 1px 0 #001c2426, -1px 1px 1px 0 #001c2426);
    padding: 25px 20px 25px 20px;
}

.recommended-action-list-style {
    list-style-type: none;
    padding-left: 0px;
    margin-top: 0px;
}

.recommended-actions-grid {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: lightgrey medium solid;
}
