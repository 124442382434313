// Mostly copied from "Table select filter" Cloudscape demo:
// https://cloudscape.aws.dev/examples/react/table-select-filter.html
// https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/styles/table-select.scss

.filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    order: 0;
    flex-grow: 10;
    margin-right: 0;
}

.text-filter {
    order: 0;
    width: 70rem;
}

.select-filter {
    max-width: 250px;
    flex-grow: 2;
    width: auto;
}
