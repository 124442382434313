@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.awscat-assessment-status-complete {
    border-top: 8px solid;
    border-color: awsui.$color-charts-green-600;
}

.awscat-assessment-status-in-progress {
    border-top: 8px solid;
    border-color: awsui.$color-charts-blue-1-500;
}

.awscat-assessment-actions {
    .icon-button {
        opacity: 1;
        cursor: pointer;
        transition: all 0.43s ease-out;

        &:hover {
            transform: scale(1.1);
            transition: all 0.15s ease-in;
        }
    }
}
