.awscat-assessment-date-input {
    margin: 3rem auto;
    width: fit-content;
}

.awscat-assessment-date-input input[type='string'] {
    height: 8rem;
    min-width: 15rem;
    margin: 3rem auto;
    padding: 1rem 3rem;
    border: solid 1px #eee;
    border-bottom: solid 2px #0073bb;
    font-size: 7rem;
    text-align: center;
    font-weight: 700;
    background: #fff;
    transition: all 0.6s ease-out;

    &:hover {
        border: solid 1px #cdcdcd;
        border-bottom: solid 2px #0073bb;
        transition: all 0.3s ease-out;
    }

    &:focus {
        background: #f3f3f3;
        border: solid 1px #ccc;
        border-bottom: solid 3px #0073bb;
        transition: all 0.3s ease-out;
        outline: none;
    }
}
