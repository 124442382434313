@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use './Header';

// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
.custom-visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.awsui-polaris-dark-mode {
    .awsui-table-header,
    .awsui-cards-header {
        color: awsui.$color-text-heading-default;
    }
}
