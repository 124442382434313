@import '@amzn/awsui-design-tokens/polaris.scss';

$assessment-heatmap-cell-font-color-default: #545b64;
$assessment-heatmap-cell-default: #ffffff;
$assessment-heatmap-cell-border-color: #eaeded;
$assessment-heatmap-cell-hover-transition-start: 0.15s;
$assessment-heatmap-cell-hover-transition-end: 0.55s;
$assessment-heatmap-cell-phase-font-color: #687078;
$assessment-heatmap-cell-phase-font-size: 1em;
$assessment-heatmap-grid-border: #aab7b8;

.heatmap-driver-dropdown {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $assessment-heatmap-grid-border;
    width: 100%;
}

.awscat-assessment-heatmap-container {
    h4.container-title {
        padding: 30px;
        font-size: 1.8em !important;
        background-color: white;
        color: rgb(35, 47, 62);

        &:not(#\9) {
            padding: 30px;
        }
    }

    .heatmap-cell {
        text-align: center;
        border-top: 1px solid $assessment-heatmap-grid-border;
        color: white;
        font-size: 1.1em;
        padding-top: 20px;

        &:not(#\9) {
            color: white;
            &:hover {
                cursor: pointer;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .heatmap-cell-category {
        text-align: center;
        border-top: solid 1px $assessment-heatmap-grid-border;
        border-bottom: solid 1px $assessment-heatmap-grid-border;
        background: rgb(35, 47, 62) !important;
        font-size: 1.05em !important;
        color: white !important;
        user-select: none;
        font-weight: bold !important;
    }

    .heatmap-cell-button {
        width: 100%;
        padding: 0;

        &:not(#\9) {
            padding-top: 25px;
        }
    }
}
