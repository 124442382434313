.assessment-timeline-nav {
    display: table;
    width: 95%;
    max-width: 150rem;
    border: solid 1px #ccc;
    border-bottom-width: 0; // don't use bottom border, since timeline is directly adjacent to prompt
    margin: 0 auto;
    padding: 0 0.6rem;
    background: #fff;
    text-align: center;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        span:last-child li {
            padding-right: 0;
        }

        li {
            display: inline-block;
            padding-right: 1rem;

            a {
                text-decoration: none;
                color: #16191f;
            }

            // pipe dividers between links
            span {
                color: #aaa;
            }

            &.active {
                a {
                    font-weight: bold;
                    color: var(--color-background-button-primary-active-39tE8I, #dd6b10);
                }
            }

            &:not(.active) {
                a {
                    color: #16191f;
                    transition: color 0.2s ease-in;
                    cursor: pointer;

                    &:hover {
                        color: var(--color-background-button-primary-active-39tE8I, #dd6b10);
                        transition: color 0.15s ease-in;
                    }
                }
            }
        }
    }
}
