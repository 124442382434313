.report-customization-wrapper {
    padding: 2rem;

    .report-customization-form {
        // Empty in case future styling should be done
    }

    .report-customization-buttons {
        display: flex;
        justify-content: right;
    }
}
